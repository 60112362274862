<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Поиск
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="12"
                class="mb-md-0 mb-2"
            >
              <label
                  for="search-query"
                  class="text-nowrap mr-50"
              >Запрос:</label>
              <b-form-input
                  id="search-query"
                  v-model="searchQuery"
                  placeholder="Savage Garden"
                  @keyup.enter="fetchArtistsList()"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-table
          ref="refArtistsListTable"
          :items="artistsList"
          responsive
          :fields="tableColumns"
          :tbody-tr-class="rowClass"
          primary-key="filmId"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
      >

        <!-- Column: Client -->
        <template #cell(artist)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :src="data.item.posterUrlPreview"
                  :text="avatarText(data.item.title)"
                  variant="primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap" :title="data.item.title">
              {{ truncateString(data.item.title, 30) }}
            </span>
          </b-media>
        </template>

        <!-- Column: Client -->
        <template #cell(tracks_count)="data">
            <span class="font-weight-bold d-block text-nowrap">
              <span :class="(data.item.downloaded_tracks_count == data.item.tracks_count)? 'text-success' : ''">
                {{ data.item.downloaded_tracks_count }} / {{ data.item.tracks_count }}
              </span>
            </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
<!--            <b-button
                v-if="!data.item.db"
                variant="success"
                class="btn-icon"
                size="sm"
                @click="addArtist(data.item)"
                :disabled="(disabled===data.item.filmId)"
            >
              <feather-icon icon="UserPlusIcon"/>
            </b-button>-->
            <b-button
                :to="{name: 'artist-view', params: { id: data.item.id }}"
                :variant="data.item.has_downloaded_tracks ? 'primary' : 'danger'"
                class="btn-icon"
                size="sm"
            >
              <feather-icon icon="ArrowRightCircleIcon"/>
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12">
        <!-- pagination -->
        <div class="my-2">
          <b-pagination
              v-if="total > 0"
              v-model="page"
              :per-page="perPage"
              align="center"
              :total-rows="total"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import {avatarText, badWord, kFormatter, truncateString} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import useArtistsList from "./useArtistsList";
import musicStoreModule from "@/views/db-music/musicStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    ContentWithSidebar,
    vSelect,
  },
  data() {
    return {
      blogSidebar: {},
      disabled: 0,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    kFormatter,
    addArtist(artist) {
      this.disabled = artist.filmId
      this.$http.post(`/artist`, {kp_id: artist.filmId, 'title_ru': artist.nameRu}).then(res => {
        let newArtist = res.data.data
        if (newArtist) {
          this.$http.post(`/artist/import-from-lastfm/${newArtist.id}`).then(res => {
            if (res.data.data) {
              this.fetchArtistsList()
            }
          })
        }
      })
    },
    rowClass(item, type) {
      const colorClass = 'table-danger'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (!item.has_downloaded_tracks) { return colorClass }
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'artists'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, musicStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const page = ref(1)
    const perPage = ref(25)
    const searchQuery = ref(null)
    const total = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'id', label: '#', sortable: false },
      { key: 'artist', sortable: false },
      { key: 'popularity', sortable: false },
      { key: 'russian', sortable: false },
      { key: 'lists_count', sortable: false },
      { key: 'tracks_count', sortable: false },
      { key: 'actions' },
    ]

    const artistsList = ref([])
    const fetchArtists = (...args) => store.dispatch('artists/fetchArtists', ...args)

    const fetchArtistsList = () => {
      fetchArtists({
        q: searchQuery.value,
        page: page.value,
        per_page: perPage.value
      })
      .then(response => {
        artistsList.value = response.data.data
        total.value = response.data.meta.total
      })
    }

    fetchArtistsList()

    watch([page], () => {
      fetchArtistsList()
    })

    const {
      currentPage,
      dataMeta,
      isSortDirDesc,
      refArtistsListTable,
    } = useArtistsList()

    return {
      fetchArtists,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      searchQuery,
      isSortDirDesc,
      refArtistsListTable,
      artistsList,
      page,
      total,
      avatarText,
      fetchArtistsList,
      truncateString,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
