import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useArtistsList() {
  // Use toast
  const toast = useToast()

  const refArtistsListTable = ref(null)

  const perPage = ref(10)
  const totalArtists = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refArtistsListTable.value ? refArtistsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalArtists.value,
    }
  })

  const refetchData = () => {
    //refArtistsListTable.value.refresh()
    //fetchArtists()
  }

  refetchData()

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchArtists = (callback) => {
    store
      .dispatch('artists/fetchArtists', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { artists, total } = response.data

        refArtistsListTable.value = artists
        totalArtists.value = total
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Artists list ' + error,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  return {
    fetchArtists,
    perPage,
    currentPage,
    totalArtists,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refArtistsListTable,

    refetchData,
  }
}
